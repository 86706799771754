
import { BarChart, Fire, Globe2} from "react-bootstrap-icons";
import {
  Home,
  Map,
  Info,
  BarChart2,
  Settings,
  Bookmark,
  Globe,
  Folder,
  DollarSign,
  Cloud,
} from "react-feather";
import {
  AlertEmailsTabRoute,
  billsRoute,
  contractPreparedRoute,
  contractSignedRoute,
  dashboardRoute,
  designRoute,
  environmentRoute,
  financeRoute,
  hotLeadAdminRoute,
  hotLeadAgentRoute,
  liveProspectSitesAgentRoute,
  liveProspectSitesRoute,
  NewSiteRoute,
  openSolarRoute,
  prospectDemoAgentRoute,
  prospectDemoRoute,
  reportingRoute,
  savedProspectsAgentRoute,
  savedProspectsRoute,
  searchPlaceAgentRoute,
  searchPlaceRoute,
  settingsRoute,
  sitesRoute,
  sitesTableRoute,
} from "src/constant/app_route_names";

export const ADMIN = [
  {
    menutitle: "General",
    menucontent: "",
    Items: [
      {
        path: dashboardRoute,
        icon: <Home />,
        type: "link",
        active: true,
        title: "Dashboard",
        disabled: false,
      },

      {
        icon: <Map />,
        type: "sub",
        active: false,
        title: "All Sites",
        disabled: false,
        children: [
          {
            path: sitesTableRoute,
            type: "link",
            active: false,
            title: "List View",
            disabled: false,
          },
          {
            path: sitesRoute,
            type: "link",
            active: false,
            title: "Map",
            disabled: false,
          },
          {
            path: NewSiteRoute,
            type: "link",
            active: false,
            title: "Add New Site",
            disabled: false,
          },
          {
            path: AlertEmailsTabRoute,
            type: "link",
            active: false,
            title: "Alert Emails",
            disabled: false,
          },
        ],
      },
      {
        icon: <Info />,
        type: "sub",
        active: false,
        title: "Leads",
        disabled: false,
        children: [
          {
            path: prospectDemoRoute,
            type: "link",
            active: false,
            title: "Search Leads",
            disabled: false,
          },
          {
            path: searchPlaceRoute,
            type: "link",
            active: false,
            title: "Search a Place",
            disabled: false,
          },
          {
            path: savedProspectsRoute,
            type: "link",
            active: false,
            title: "Saved Leads",
            disabled: false,
          },

          // {
          //   path: liveProspectSitesRoute,
          //   type: "link",
          //   active: false,
          //   title: "Live Sites",
          //   disabled: false,
          // },
        ],
      },

      {
        path: hotLeadAdminRoute,
        icon: <Folder size={20} />,
        type: "link",
        active: true,
        title: "Prospects",
        disabled: false,
      },

      {
        path: liveProspectSitesRoute,
        icon: <Globe />,
        type: "link",
        active: true,
        title: "Live Sites",
        disabled: false,
      },

      
      {
        path: financeRoute,
        icon: <DollarSign />,
        type: "link",
        active: false,
        title: "Finance",
        disabled: false,
      },
      {
        path: environmentRoute,
        icon: <Cloud />,
        type: "link",
        active: false,
        title: "Environment",
        disabled: false,
      },
      {
        path: reportingRoute,
        icon: <BarChart2 />,
        type: "link",
        active: false,
        title: "Reporting",
        disabled: false,
      },
      {
        icon: <Settings />,
        type: "sub",
        active: false,
        title: "Settings",
        disabled: false,
        children: [
          {
            path: settingsRoute,
            type: "link",
            active: false,
            title: "System Setup",
            disabled: false,
          },
          {
            path: openSolarRoute,
            type: "link",
            active: false,
            title: "Opensolar",
            disabled: false,
          },

          {
            path: billsRoute,
            type: "link",
            active: false,
            title: "Bills",
            disabled: false,
          },
          {
            path: designRoute,
            type: "link",
            active: false,
            title: "Solar Potential",
            disabled: false,
          },
        ],
      },

      
    ],
  },
];

export const SALESAGENT = [
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        icon: <Info />,
        type: "sub",
        active: true,
        title: "Leads",
        disabled: false,
        children: [
          {
            icon: Map,
            // icon: <Map />,
            path: prospectDemoAgentRoute,
            type: "link",
            active: true,
            title: "Search Leads",
            disabled: false,
          },
          {
            path: searchPlaceAgentRoute,
            type: "link",
            active: false,
            title: "Search a Place",
            disabled: false,
          },
          {
            icon: Bookmark,
            // icon: <Bookmark />,
            path: savedProspectsAgentRoute,
            type: "link",
            active: false,
            title: "Saved Leads",
            disabled: false,
          },
        ],
      },
      
     
      {
        path: hotLeadAgentRoute,
        icon: <Folder size={20} />,
        type: "link",
        active: true,
        title: "Prospects",
        disabled: false,
      },
      {
        path: liveProspectSitesAgentRoute,
        icon: <Globe />,
        type: "link",
        active: true,
        title: "Live Sites",
        disabled: false,
      },
      
      {
        // path: sitesRoute,
        icon: <Map />,
        type: "sub",
        active: false,
        title: "Contracts",
        disabled: false,
        // path:sitesTableRoute,
        children: [
          {
            path: contractPreparedRoute,
            type: "link",
            active: false,
            title: "Contracts Prepared",
            disabled: false,
          },
          {
            path: contractSignedRoute,
            type: "link",
            active: false,
            title: "Contracts Signed",
            disabled: false,
          },
        ],
      },
    ],
  },
];

export const Manager = [
  {
    menutitle: "",
    menucontent: "",
    Items: [],
  },
];
